/**
 * This is the address bar that displays the current activeLocation.
 * Tapping should open up the address select modal.
 */

import { Fragment, useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import { breakpoint } from '@/theme'
import { TEST_IDS } from '@components/address/test/constants'

const AddressDisplay = () => {
  const containerRef = useRef(null)
  const loading = useSelector((state) => state.loading.address)
  const location = useSelector((state) => state.location)
  const [addressTitle, setAddressTitle] = useState('')

  const address = location && location.activeLocation

  useEffect(() => {
    if (address) {
      const CITY_ADDRESS_TYPES = ['locality', 'political']
      const enterAddressText = 'Please enter your address'

      if (location.inSampleLocation) {
        setAddressTitle(enterAddressText)
      } else if (!address.city && address.zip) {
        setAddressTitle(address.zip)
      } else if (address.types && address.types.some((type) => CITY_ADDRESS_TYPES.includes(type))) {
        setAddressTitle(address.city || enterAddressText)
      } else {
        setAddressTitle(address.title || enterAddressText)
      }
    }
  }, [address, location.inSampleLocation])

  if (!address) return null

  return (
    <AddressContainer ref={containerRef} className="fs-mask" data-e2eid={TEST_IDS.ADDRESS_PICKER}>
      {loading ? 'Updating location...' : <Fragment>{addressTitle}</Fragment>}
    </AddressContainer>
  )
}

const AddressContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  line-height: 1;
  @media (min-width: ${breakpoint.min.lg}px) {
    padding-left: 10px;
  }
`

export default AddressDisplay
